import {
  AppstoreOutlined,
  BarChartOutlined, CalendarOutlined,
  ContactsOutlined,
  DatabaseOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  FunnelPlotOutlined, GlobalOutlined,
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  RadarChartOutlined,
  SearchOutlined,
  SettingOutlined,
  ShopOutlined,
  SmileOutlined,
  TeamOutlined,
  WarningOutlined,
  ZoomInOutlined
} from '@ant-design/icons';
import { MenuProps } from 'antd';
import User from 'graphql/User/User';
import { DashboardPage, UserRole } from 'graphql/apollo-graphql-generated/globalTypes';
import castArray from 'lodash/castArray';
import isEmpty from 'lodash/isEmpty';
import { lazy } from 'react';
import { Route, RouteComponentProps, RouteProps } from "react-router";
import { NavLink } from 'react-router-dom';

const BotEvaluationPage = lazy(() => import('pages/Chats/Evaluations/BotEvaluationPage/BotEvaluationPage'))
const AttendantEvaluationPage = lazy(() => import('pages/Chats/Evaluations/AttendantEvaluationPage/AttendantEvaluationPage'))
const RecommendedRealEstatesPage = lazy(() => import('pages/Clients/RecommendedRealEstatesPage'))
const ChatClientDetailsPage = lazy(() => import('pages/Clients/ChatClientDetailsPage/ChatClientDetailsPage'))
const ChatClientGroupPage = lazy(() => import('pages/Clients/ChatClientGroupPage/ChatClientGroupPage'))
const UserEditPage = lazy(() => import('pages/User/UserPage/UserEditPage'))
const ClientsStatisticsPage = lazy(() => import('../Clients/ClientsStatisticsPage/ClientsStatisticsPage'))
const ClientsChoicesPage = lazy(() => import('../Clients/ClientChoicesPage/ClientChoicesPage'))
const VisitsCalendarPage = lazy(() => import('../Clients/VisitsCalendarPage'))
const AllTimeStatistics = lazy(() => import('pages/Management/AllTimeStatisticsPage'))
const AgencyChildEditPage = lazy(() => import('pages/Management/AgencyChildPage/AgencyChildEditPage'))
const MessagesToSalesmanPage = lazy(() => import('pages/Clients/MessagesToSalesmanPage'))
const UsersPage = lazy(() => import('pages/Management/UsersPage'))
const AgenciesPage = lazy(() => import('pages/Management/AgenciesPage'))
const ChatsHistoryPage = lazy(() => import('pages/Chats/ChatsHistoryPage/ChatsHistoryPage'))
const FailChatsPage = lazy(() => import('pages/Chats/FailChatsPage/FailChatsPage'))
const RealEstatesStatisticsPage = lazy(() => import('pages/RealEstate/RealEstatesStatisticsPage'))
const RealEstatesRecommendationPage = lazy(() => import('pages/RealEstate/RealEstatesRecommendationPage'))
const RealEstatesSearchPage = lazy(() => import('pages/RealEstate/RealEstatesSearchPage'))
const RealEstatesDataQualityPage = lazy(() => import('pages/RealEstate/RealEstatesDataQualityPage'))
const ChangePasswordPage = lazy(() => import('pages/User/ChangePasswordPage'))
const UserCreatePage = lazy(() => import('pages/User/UserPage/UserCreatePage'))
const ResetPasswordPage = lazy(() => import('pages/User/ResetPasswordPage'))
const ChatWidgetEditPage = lazy(() => import('pages/Management/ChatWidgetEditPage/ChatWidgetEditPage'))
const ClientEditPage = lazy(() => import('pages/Chats/ClientEditPage/ClientEditPage'))
const RealTimeGraphsPage = lazy(() => import('pages/Chats/RealTimeGraphsPage/RealTimeGraphsPage'))
const AgencyParentEditPage = lazy(() => import('pages/Management/AgencyParentEditPage/AgencyParentEditPage'))
const AgencyChildCreatePage = lazy(() => import('pages/Management/AgencyChildPage/AgencyChildCreatePage'))
const RealEstateAgenciesDatabasesPage = lazy(() => import('pages/Management/AgencyDatabase/RealEstateAgenciesDatabasesPage'))
const RealEstateAgencyDatabaseEditPage = lazy(() => import('pages/Management/AgencyDatabase/RealEstateAgencyDatabaseEditPage'))
const ClientFileUploadDownload = lazy(() => import('pages/Clients/ChatClientDetailsPage/components/ClientFileUploadDownload'))
const PortfolioAnalysisPage = lazy(() => import('pages/RealEstate/PortfolioAnalysisPage'))
const RegionEditPage = lazy(() => import('pages/Management/RegionEditPage'))
const ClientEmailsPage = lazy(() => import('pages/Clients/ClientEmailsPage/ClientEmailsPage'))
const ChatOnlinePage = lazy(() => import('pages/Chats/ChatOnlinePage/ChatOnlinePage'))
const NewChatOnlinePage = lazy(() => import('pages/Chats/ChatOnlinePage/pages/NewChatOnlinePage/NewChatOnlinePage'))
const ChatOnlineSelectPage = lazy(() => import('pages/Chats/ChatOnlinePage/pages/ChatOnlineSelectPage/ChatOnlineSelectPage'))
const FunnelStepsPage = lazy(() => import('pages/Clients/FunnelStepsPage/FunnelStepsPage'))
const FunnelStepsEditPage = lazy(() => import('pages/Clients/FunnelStepsPage/FunnelStepsEditPage'))
const FunnelStepsViewerPage = lazy(() => import('pages/Clients/FunnelStepsPage/FunnelStepsViewerPage'))
const ExternalChatChannelEditPage = lazy(() => import('pages/Management/ExternalChatChannelEditPage/ExternalChatChannelEditPage'))
const ExternalChatChannelStatsPage = lazy(() => import('pages/Chats/ExternalChatChannelStatsPage/ExternalChatChannelStatsPage'))
const AgencyFileUploadDownload = lazy(() => import('pages/Management/AgencyChildPage/components/AgencyChildFileUploadDownload'))
const ChatOnlineStatusHistoryPage = lazy(() => import('pages/Chats/ChatOnlinePage/pages/ChatOnlineStatusHistoryPage/ChatOnlineStatusHistoryPage'))
const RoulettePage = lazy(() => import('pages/Management/RoulettePage/RoulettePage'))
const RouletteEditPage = lazy(() => import('pages/Management/RoulettePage/RouletteEditPage'))
const DeletedClientsPage = lazy(() => import('pages/Chats/DeletedClientsPage/DeletedClientsPage'))
const ErrorsOccurredPage = lazy(() => import('pages/Clients/ErrorsOccurredPage/ErrorsOccurredPage'))
const RouletteEventsPage = lazy(() => import('pages/Management/RouletteEventsPage/RouletteEventsPage'))
const ScheduleMessagesPage = lazy(() => import('pages/Management/ScheduleMessagesPage/ScheduleMessagesPage'))
const ScheduleMessagesReportPage = lazy(() => import('pages/Management/ScheduleMessagesPage/ScheduleMessagesReportPage'))

export type RouteKey =
  'agencies' |
  'agencyChildCreate' |
  'agencyChildEdit' |
  'agencyChildFileUpload' |
  'agencyParentCreate' |
  'agencyParentEdit' |
  'botEvaluations' |
  'attendantEvaluations' |
  'chatOnline' |
  'chatOnlineStatusHistory' |
  'chatOnlineExternalChatChannel' |
  'oldChatOnlineExternalChatChannel' |
  'chatWidgetCreate' |
  'chatWidgetEdit' |
  'client' |
  'clientOpenPage' |
  'clientCreate' |
  'clientEdit' |
  'clientFileUpload' |
  'clientGroup' |
  'clients' |
  'clientsChoices' |
  'clientsEmails' |
  'clientsStatistics' |
  'clientsSums' |
  'deletedClients' |
  'errorsOccurred' |
  'externalChatChannelEdit' |
  'externalChatChannelStats' |
  'failChat' |
  'funnelSteps' |
  'funnelStepsEdit' |
  'funnelStepsViewer' |
  'messagesToSalesman' |
  'myProfile' |
  'passwordChange' |
  'passwordReset' |
  'portfolioAnalysis' |
  'realEstateAgenciesDatabases' |
  'realEstateAgencyDatabaseCreate' |
  'realEstateAgencyDatabaseEdit' |
  'realEstatesDataQuality' |
  'realEstatesRecommendation' |
  'realEstatesSearch' |
  'realEstatesStatistics' |
  'realTimeGraphs' |
  'recommendedRealEstates' |
  'regionEdit' |
  'roulette' |
  'rouletteEdit' |
  'rouletteEvents' |
  'scheduleMessages' |
  'scheduleMessagesReport' |
  'userCreate' |
  'userEdit' |
  'users' |
  'visitsCalendar'
  ;

export interface RouteConfig {
  /** true if the route is to be accessible without login */
  isOpenRoute?: boolean
  /** true if the route is to be accessible to harry users */
  isWhitelabelRoute?: boolean
  /** react router props */
  routeProps?: RouteProps | RouteProps[]
  /** which user roles have access to the page. If not defined, all users will have access. */
  roles?: UserRole | UserRole[]
  /** menu name registered in prisma */
  nameInPrisma?: DashboardPage
  /** is the route accessible for all users, including not logged in ones? */
  // isOpenRoute?: boolean
  /** get the url for the route */
  getUrl?: <T extends any[]>(...args: T) => string
}

export type Routes = Record<RouteKey, RouteConfig>

export interface MenuItem {
  /** key for the menu link. If not specified, the key will be set to routeKey */
  key?: string
  /** the route key. If set, the menu link will use the route config for the specified route key */
  routeKey?: RouteKey
  /** returns a boolean indicating if the menu item should be hidden, even if the user role has access to it */
  shouldHide?: (user: User) => boolean
  /** name to show in the main menu. If not set, will not be shown in the menu */
  nameInMenu?: string
  /** menu name registered in prisma */
  nameInPrisma?: DashboardPage
  /** antd icon type */
  icon?: JSX.Element
  /** which user roles have access to the page. If not defined, all users will have access. */
  roles?: UserRole | UserRole[]
  /** children pages */
  children?: MenuItem[]
}

// export interface Context {
//   user: User
//   selectedWidgetIds: string[]
//   selectedAgencyIds: string[]
// }

export const ROUTES: Routes = {
  agencies: {
    routeProps: {
      path: '/agencies',
      component: AgenciesPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.AGENCIES_PAGE,
    getUrl: () => '/agencies',
  },

  agencyChildCreate: {
    routeProps: {
      path: '/agencyParent/:id/createAgencyChild',
      exact: true,
      render: (props: RouteComponentProps<{ id?: string }>) => <AgencyChildCreatePage agencyParentId={props.match.params.id} />
    },
    getUrl: (...args) => `/agencyParent/${args[0]}/createAgencyChild`,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN],
    nameInPrisma: DashboardPage.AGENCIES_PAGE,
  },

  agencyChildEdit: {
    routeProps: {
      path: '/agencyChild/:id/edit',
      exact: true,
      component: AgencyChildEditPage,
    },
    getUrl: (...args) => `/agencyChild/${args[0]}/edit`,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.AGENCIES_PAGE,
  },

  agencyParentCreate: {
    routeProps: {
      path: '/agencyParent/create',
      exact: true,
      render: () => <AgencyParentEditPage action={'create'} />
    },
    getUrl: () => '/agencyParent/create',
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN],
    nameInPrisma: DashboardPage.AGENCIES_PAGE,
  },

  agencyParentEdit: {
    routeProps: {
      path: '/agencyParent/:id/edit',
      render: (props: RouteComponentProps<{ id?: string }>) => <AgencyParentEditPage action={'edit'} agencyParentId={props.match.params.id} />
    },
    getUrl: (...args) => `/agencyParent/${args[0]}/edit`,
    nameInPrisma: DashboardPage.AGENCIES_PAGE,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER],
  },

  botEvaluations: {
    routeProps: {
      path: '/botEvaluations',
      render: () => <BotEvaluationPage />
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.BOT_EVALUATIONS_PAGE,
  },

  attendantEvaluations: {
    routeProps: {
      path: '/attendantEvaluations',
      render: () => <AttendantEvaluationPage />
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.ATTENDANT_EVALUATIONS_PAGE,
  },

  chatOnline: {
    routeProps: {
      path: '/chatOnline',
      component: ChatOnlineSelectPage,
      exact: true,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER, UserRole.AGENT_SALESMAN],
    nameInPrisma: DashboardPage.CHAT_ONLINE_PAGE,
    getUrl: () => `/chatOnline`,
  },

  chatOnlineStatusHistory: {
    routeProps: {
      path: '/chatOnline/externalChatChannel/:externalChatChannelId/statusHistory',
      render: (props: RouteComponentProps<{ externalChatChannelId?: string }>) =>
        <ChatOnlineStatusHistoryPage externalChatChannelId={props.match.params.externalChatChannelId} />,
    },
    roles: [UserRole.FIREFLY_ADMIN],
    getUrl: (...args) => `/chatOnline/externalChatChannel/${args[0]}/statusHistory`,
    nameInPrisma: DashboardPage.CHAT_ONLINE_PAGE,
  },

  chatOnlineExternalChatChannel: {
    routeProps: {
      path: '/chatOnline/externalChatChannel/:externalChatChannelId',
      render: (props: RouteComponentProps<{ externalChatChannelId?: string }>) =>
        <NewChatOnlinePage externalChatChannelIds={props.match.params.externalChatChannelId} />,
    },
    nameInPrisma: DashboardPage.CHAT_ONLINE_PAGE,
    isOpenRoute: true,
    isWhitelabelRoute: true,
    getUrl: (...args) => `/chatOnline/externalChatChannel/${Array.isArray(args[0]) ? args[0].join(',') : args[0]}`,
  },

  oldChatOnlineExternalChatChannel: {
    routeProps: {
      path: '/oldChatOnline/externalChatChannel/:externalChatChannelId',
      render: (props: RouteComponentProps<{ externalChatChannelId?: string }>) =>
        <ChatOnlinePage externalChatChannelIds={props.match.params.externalChatChannelId} />,
    },
    nameInPrisma: DashboardPage.CHAT_ONLINE_PAGE,
    isOpenRoute: true,
    getUrl: (...args) => `/oldChatOnline/externalChatChannel/${Array.isArray(args[0]) ? args[0].join(',') : args[0]}`,
  },

  chatWidgetCreate: {
    routeProps: {
      path: '/agencyChild/:id/createChatWidget',
      render: (props: RouteComponentProps<{ id?: string }>) => <ChatWidgetEditPage agencyId={props.match.params.id} action='create' />,
    },
    getUrl: (...args) => `/agencyChild/${args[0]}/createChatWidget`,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.AGENCIES_PAGE,
  },

  chatWidgetEdit: {
    routeProps: {
      path: '/chatWidget/:id/edit',
      render: (props: RouteComponentProps<{ id?: string }>) => <ChatWidgetEditPage widgetId={props.match.params.id} action='edit' />,
    },
    getUrl: (...args) => `/chatWidget/${args[0]}/edit`,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.AGENCIES_PAGE,
  },

  clientFileUpload: {
    routeProps: {
      path: ['/client/:id/fileUpload/:fileName/fileNameOverride/:fileNameOverride', '/client/:id/fileUpload/:fileName'],
      render: (props: RouteComponentProps<{ id?: string, fileName?: string, fileNameOverride?: string }>) =>
        <ClientFileUploadDownload
          clientId={props.match.params.id}
          fileName={props.match.params.fileName}
          fileNameOverride={props.match.params.fileNameOverride}
        />,
    },
    getUrl: (...args) => `/client/${args[0]}/fileUpload/${args[1]}` + (args[2] ? `/fileNameOverride/${args[2]}` : ''),
    nameInPrisma: DashboardPage.CLIENTS_PAGE,
  },

  agencyChildFileUpload: {
    routeProps: {
      path: ['/agencyChild/:id/fileUpload/:fileName/fileNameOverride/:fileNameOverride', '/agencyChild/:id/fileUpload/:fileName'],
      render: (props: RouteComponentProps<{ id?: string, fileName?: string, fileNameOverride?: string }>) =>
        <AgencyFileUploadDownload
          agencyId={props.match.params.id}
          fileName={props.match.params.fileName}
          fileNameOverride={props.match.params.fileNameOverride}
        />,
    },
    getUrl: (...args) => `/agencyChild/${args[0]}/fileUpload/${args[1]}` + (args[2] ? `/fileNameOverride/${args[2]}` : ''),
    nameInPrisma: DashboardPage.AGENCIES_PAGE,
  },

  clientCreate: {
    routeProps: {
      path: '/client/create',
      render: () => <ClientEditPage action={'create'} />,
    },
    getUrl: () => '/client/create',
    nameInPrisma: DashboardPage.CLIENTS_PAGE,
  },

  clientEdit: {
    routeProps: {
      path: '/client/:id/edit',
      render: (props: RouteComponentProps<{ id?: string }>) => <ClientEditPage action={'edit'} clientId={props.match.params.id} />,
    },
    getUrl: (...args) => `/client/${args[0]}/edit`,
    nameInPrisma: DashboardPage.CLIENTS_PAGE,
  },

  client: {
    routeProps: {
      path: ['/client/:id', '/ClientDetails/:id', '/ClientDetails2/:id'],
      // exact: true,
      render: (props: RouteComponentProps<{ id?: string }>) => <ChatClientDetailsPage clientId={props.match.params.id} />,
    },
    // isOpenRoute: true,
    getUrl: (...args) => `/client/${args[0]}`,
    nameInPrisma: DashboardPage.CLIENTS_PAGE,
  },

  // same route as above, but displayed only when user is not logged in. 
  // this one must use 'exact' because '/login' can be appended to the url, and the user will be redirected to the login page. (e.g. /client/:id/login)
  clientOpenPage: {
    routeProps: {
      path: ['/client/:id', '/ClientDetails/:id', '/ClientDetails2/:id'],
      exact: true,
      render: (props: RouteComponentProps<{ id?: string }>) => <ChatClientDetailsPage clientId={props.match.params.id} />,
    },
    isOpenRoute: true,
    getUrl: (...args) => `/client/${args[0]}`,
  },

  clientGroup: {
    routeProps: {
      path: ['/clientGroup/:id'],
      // exact: true,
      render: (props: RouteComponentProps<{ id?: string }>) => <ChatClientGroupPage groupId={props.match.params.id} />,
    },
    // isOpenRoute: true,
    getUrl: (...args) => `/clientGroup/${args[0]}`,
    nameInPrisma: DashboardPage.CLIENT_GROUP_PAGE,
  },

  clients: {
    routeProps: {
      path: ['/', '/clients', '/chatsHistory'],
      exact: true,
      component: ChatsHistoryPage,
    },
    nameInPrisma: DashboardPage.CLIENTS_PAGE,
    getUrl: () => '/'
  },

  // clientsSearch: {
  //   routeProps: {
  //     path: '/clientsSearch',
  //     component: SearchClientsPage,
  //   },
  //   roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
  //   nameInPrisma: DashboardPage.CLIENTS_SEARCH_PAGE,
  //   getUrl: () => `/`,
  // },

  clientsChoices: {
    routeProps: {
      path: '/clientsChoices',
      component: ClientsChoicesPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.CLIENTS_CHOICES_PAGE,
  },

  clientsEmails: {
    routeProps: {
      path: '/clientsEmails',
      component: ClientEmailsPage,
    },
    roles: UserRole.FIREFLY_ADMIN,
    nameInPrisma: DashboardPage.CLIENTS_EMAILS_PAGE,
  },

  clientsStatistics: {
    routeProps: {
      path: '/clientsStatistics',
      component: ClientsStatisticsPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.CLIENTS_ESTATISTICS_PAGE,
  },

  clientsSums: {
    routeProps: {
      path: '/clientsSums',
      component: AllTimeStatistics,
    },
    roles: UserRole.FIREFLY_ADMIN,
    nameInPrisma: DashboardPage.CLIENTS_SUM_PAGE,
  },

  deletedClients: {
    routeProps: {
      path: '/deletedClients',
      component: DeletedClientsPage,
    },
    // roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_MANAGER],
    getUrl: () => '/deletedClients',
    nameInPrisma: DashboardPage.DELETED_CLIENTS_PAGE,
  },

  errorsOccurred: {
    routeProps: {
      path: '/errorsOccurred',
      component: ErrorsOccurredPage,
    },
    roles: [UserRole.FIREFLY_ADMIN],
    nameInPrisma: DashboardPage.ERRORS_OCCURRED_PAGE,
  },

  externalChatChannelEdit: {
    routeProps: {
      path: '/externalChatChannel/:externalChatChannelId/edit',
      render: (props: RouteComponentProps<{ externalChatChannelId?: string }>) =>
        <ExternalChatChannelEditPage externalChatChannelId={props.match.params.externalChatChannelId} />,
    },
    getUrl: (...args) => `/externalChatChannel/${args[0]}/edit`,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER],
    // nameInPrisma: DashboardPage.ERRORS_OCCURRED_PAGE,
  },

  externalChatChannelStats: {
    routeProps: {
      path: '/externalChatChannelStats',
      render: () => <ExternalChatChannelStatsPage />
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    getUrl: () => `/externalChatChannelStats`,
    nameInPrisma: DashboardPage.EXTERNAL_CHAT_CHANNEL_STATS_PAGE,
  },

  failChat: {
    routeProps: {
      path: '/failChat',
      component: FailChatsPage,
    },
    roles: UserRole.FIREFLY_ADMIN,
    nameInPrisma: DashboardPage.FAIL_CHAT_PAGE,
  },

  funnelSteps: {
    routeProps: {
      path: '/funnelSteps',
      exact: true,
      component: FunnelStepsPage,
    },
    //roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_MANAGER, UserRole.AGENT_SALESMAN],
    nameInPrisma: DashboardPage.FUNNEL_STEPS_PAGE,
  },

  funnelStepsEdit: {
    routeProps: {
      path: '/funnelSteps/:id/edit',
      exact: true,
      component: FunnelStepsEditPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    getUrl: (...args) => `/funnelSteps/${args[0]}/edit`,
    nameInPrisma: DashboardPage.FUNNEL_STEPS_EDIT_PAGE,
  },

  funnelStepsViewer: {
    routeProps: {
      path: '/funnelSteps/:id',
      exact: true,
      component: FunnelStepsViewerPage,
    },
    getUrl: (...args) => `/funnelSteps/${args[0]}`,
    nameInPrisma: DashboardPage.FUNNEL_STEPS_PAGE,
  },

  messagesToSalesman: {
    routeProps: {
      path: '/messagesToSalesman',
      component: MessagesToSalesmanPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.MESSAGES_TO_SALESMAN_PAGE,
  },

  myProfile: {
    routeProps: {
      path: '/user/me/edit',
      exact: true,
      render: () => <UserEditPage userId='me' />,
    },
  },

  passwordChange: {
    routeProps: {
      path: '/passwordChange',
      component: ChangePasswordPage,
    },
  },

  passwordReset: {
    routeProps: {
      path: ['/passwordReset/:id', '/resetPassword/:id',],
      exact: true,
      component: ResetPasswordPage,
    },
    isOpenRoute: true,
  },

  portfolioAnalysis: {
    routeProps: {
      path: '/portfolioAnalysis',
      component: PortfolioAnalysisPage,
    },
    roles: [UserRole.AGENT_MANAGER, UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT],
    nameInPrisma: DashboardPage.PORTIFOLIO_ANALYSIS_PAGE,
  },

  realEstateAgenciesDatabases: {
    routeProps: {
      path: '/realEstateAgenciesDatabases/',
      component: RealEstateAgenciesDatabasesPage,
      exact: true,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN],
    nameInPrisma: DashboardPage.REAL_ESTATE_AGENCIES_DATABASES_PAGE,
    getUrl: () => `/realEstateAgenciesDatabases/`,
  },

  realEstateAgencyDatabaseCreate: {
    routeProps: {
      path: '/realEstateAgencyDatabase/create',
      render: () => <RealEstateAgencyDatabaseEditPage action='create' />,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.HARRY_ADMIN],
    nameInPrisma: DashboardPage.REAL_ESTATE_AGENCIES_DATABASES_PAGE,
    getUrl: () => `/realEstateAgencyDatabase/create`,
  },

  realEstateAgencyDatabaseEdit: {
    routeProps: {
      path: '/realEstateAgencyDatabase/:id/edit',
      render: (props: RouteComponentProps<{ id?: string }>) => <RealEstateAgencyDatabaseEditPage action='edit' id={props.match.params.id} />,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN],
    nameInPrisma: DashboardPage.REAL_ESTATE_AGENCIES_DATABASES_PAGE,
    getUrl: (...args) => `/realEstateAgencyDatabase/${args[0] || ''}/edit`,
  },

  realEstatesDataQuality: {
    routeProps: {
      path: ['/realEstatesDataQuality/:id', '/realEstatesDataQuality'],
      render: (props: RouteComponentProps<{ id?: string }>) => <RealEstatesDataQualityPage agenciesDatabasesIds={props.match.params.id} />,
    },
    nameInPrisma: DashboardPage.REAL_ESTATE_DATA_QUALITY_PAGE,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER, UserRole.AGENT_CONSULTANT],
    getUrl: (...args) => `/realEstatesDataQuality/${args[0] || ''}`,
  },

  realEstatesRecommendation: {
    routeProps: {
      path: '/realEstatesRecommendation',
      component: RealEstatesRecommendationPage,
    },
    nameInPrisma: DashboardPage.REAL_ESTATE_RECOMMENDATION_PAGE,
  },

  realEstatesSearch: {
    routeProps: {
      path: ['/realEstatesSearch/:id', '/realEstatesSearch'],
      // component: RealEstatesSearchPage,
      render: (props: RouteComponentProps<{ id?: string }>) => <RealEstatesSearchPage agencyDatabaseId={props.match.params.id} />,
    },
    nameInPrisma: DashboardPage.REAL_ESTATE_SEARCH_PAGE,
    getUrl: (...args) => `/realEstatesSearch/${args[0] || ''}`,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER, UserRole.AGENT_CONSULTANT],
  },

  realEstatesStatistics: {
    routeProps: {
      path: ['/realEstatesStatistics/:id', '/realEstatesStatistics'],
      render: (props: RouteComponentProps<{ id?: string }>) => <RealEstatesStatisticsPage agenciesDatabasesIds={props.match.params.id} />,
    },
    getUrl: (...args) => `/realEstatesStatistics/${args[0] || ''}`,
    nameInPrisma: DashboardPage.REAL_ESTATE_STATISTICS_PAGE,
  },

  realTimeGraphs: {
    routeProps: {
      path: ['/realTimeGraphs'],
      exact: true,
      component: RealTimeGraphsPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_MANAGER, UserRole.AGENT_CONSULTANT],
    nameInPrisma: DashboardPage.REAL_TIME_GRAPHS_PAGE,
    getUrl: () => '/realTimeGraphs'
  },

  recommendedRealEstates: {
    routeProps: {
      path: '/recommendedRealEstates/:id',
      component: RecommendedRealEstatesPage,
    },
    isOpenRoute: true,
  },

  regionEdit: {
    routeProps: {
      path: '/regionEdit',
      component: RegionEditPage,
    },
    roles: [UserRole.FIREFLY_ADMIN],
    nameInPrisma: DashboardPage.REGION_EDIT_PAGE,
  },

  roulette: {
    routeProps: {
      path: '/roulette',
      exact: true,
      component: RoulettePage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_MANAGER],
    getUrl: () => '/roulette',
    nameInPrisma: DashboardPage.ROULETTE_PAGE,
  },

  rouletteEdit: {
    routeProps: {
      path: '/roulette/:id/edit',
      exact: true,
      component: RouletteEditPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_MANAGER],
    getUrl: (...args) => `/roulette/${args[0]}/edit`,
    nameInPrisma: DashboardPage.ROULETTE_PAGE,
  },

  rouletteEvents: {
    routeProps: {
      path: '/rouletteEvents',
      exact: true,
      component: RouletteEventsPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_MANAGER],
    getUrl: () => '/rouletteEvents',
    nameInPrisma: DashboardPage.ROULETTE_PAGE,
  },

  scheduleMessages: {
    routeProps: {
      path: '/scheduleMessages',
      exact: true,
      component: ScheduleMessagesPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_MANAGER],
    getUrl: () => '/scheduleMessages',
    nameInPrisma: DashboardPage.SCHEDULE_MESSAGES_PAGE,
  },

  scheduleMessagesReport: {
    routeProps: {
      path: '/scheduleMessagesReport',
      exact: true,
      component: ScheduleMessagesReportPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.AGENT_MANAGER],
    getUrl: () => '/scheduleMessagesReport',
    nameInPrisma: DashboardPage.SCHEDULE_MESSAGES_PAGE,
  },

  userCreate: {
    routeProps: {
      path: '/user/create',
      exact: true,
      render: () => <UserCreatePage />,
    },
    getUrl: () => `/user/create`,
    nameInPrisma: DashboardPage.USER_CREATE_PAGE,
  },

  userEdit: {
    routeProps: {
      path: '/user/:id/edit',
      exact: true,
      render: (props: RouteComponentProps<{ id?: string }>) => <UserEditPage userId={props.match.params.id} />,
    },
    getUrl: (...args) => `/user/${args[0]}/edit`,
    nameInPrisma: DashboardPage.USER_EDIT_PAGE,
  },

  users: {
    routeProps: {
      path: '/users',
      component: UsersPage,
      exact: true,
    },
    getUrl: () => `/users`,
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.USERS_PAGE,
  },

  visitsCalendar: {
    routeProps: {
      path: '/visitsCalendar',
      component: VisitsCalendarPage,
    },
    roles: [UserRole.FIREFLY_ADMIN, UserRole.HARRY_ADMIN, UserRole.AGENT_CONSULTANT, UserRole.AGENT_MANAGER],
    nameInPrisma: DashboardPage.VISITS_CALENDAR_PAGE,
  },
}

export const MENU_ITEMS: MenuItem[] = [
  {
    key: 'clientsMain',
    nameInMenu: 'Clientes',
    icon: <TeamOutlined />,
    children: [
      {
        routeKey: 'clients',
        nameInMenu: 'Clientes',
        nameInPrisma: DashboardPage.CLIENTS_PAGE,
        icon: <TeamOutlined />,
        roles: ROUTES.clients.roles,
      },
      {
        routeKey: 'funnelSteps',
        nameInPrisma: DashboardPage.FUNNEL_STEPS_PAGE,
        nameInMenu: 'Funil de processos',
        icon: <FunnelPlotOutlined />,
        roles: ROUTES.funnelSteps.roles,
      },
      {
        routeKey: 'botEvaluations',
        nameInPrisma: DashboardPage.BOT_EVALUATIONS_PAGE,
        nameInMenu: 'Avaliações do bot',
        icon: <SmileOutlined />,
        roles: ROUTES.botEvaluations.roles,
      },
      {
        routeKey: 'attendantEvaluations',
        nameInMenu: 'Avaliações do atendente',
        icon: <SmileOutlined />,
        roles: ROUTES.attendantEvaluations.roles,
        nameInPrisma: DashboardPage.ATTENDANT_EVALUATIONS_PAGE,
      },
      {
        routeKey: 'chatOnline',
        nameInMenu: 'Chat Online',
        nameInPrisma: DashboardPage.CHAT_ONLINE_PAGE,
        icon: <MessageOutlined />,
        roles: ROUTES.chatOnline.roles,
        // hide if there are no external chat channels available
        // shouldHide: (user) => !user?.agent?.agencies?.some(agency =>
        //   agency.chatWidgets?.some(widget =>
        //     widget.externalChatChannels?.length > 0
        //   )),
      },
      {
        routeKey: 'realTimeGraphs',
        nameInMenu: 'Gráficos em tempo real',
        nameInPrisma: DashboardPage.REAL_TIME_GRAPHS_PAGE,
        icon: <BarChartOutlined />,
        roles: ROUTES.realTimeGraphs.roles,
      },
      {
        routeKey: 'clientsStatistics',
        nameInMenu: 'Estatísticas',
        nameInPrisma: DashboardPage.CLIENTS_ESTATISTICS_PAGE,
        icon: <BarChartOutlined />,
        roles: ROUTES.clientsStatistics.roles,
      },
      // {
      //   routeKey: 'clientsSearch',
      //   nameInMenu: 'Busca Personalizada',
      //   nameInPrisma: DashboardPage.CLIENTS_SEARCH_PAGE,
      //   icon: <SearchOutlined />,
      //   roles: ROUTES.clientsSearch.roles,
      // },
      // {
      //   routeKey: 'crmStatistics',
      //   nameInMenu: 'Estatísticas de CRM',
      //   icon: <BarChartOutlined />,
      //   roles: ROUTES.crmStatistics.roles,
      // },
      // {
      //   routeKey: 'externalLeadStatistics',
      //   nameInMenu: 'Estatísticas de Lead Externos',
      //   icon: <BarChartOutlined />,
      //   roles: ROUTES.externalLeadStatistics.roles,
      // },
      {
        routeKey: 'clientsChoices',
        nameInMenu: 'Preferências',
        nameInPrisma: DashboardPage.CLIENTS_CHOICES_PAGE,
        icon: <BarChartOutlined />,
        roles: ROUTES.clientsChoices.roles,
      },
      {
        routeKey: 'visitsCalendar',
        nameInMenu: 'Solicitações de visita',
        nameInPrisma: DashboardPage.VISITS_CALENDAR_PAGE,
        icon: <CalendarOutlined />,
        roles: ROUTES.visitsCalendar.roles,
      },
      {
        routeKey: 'messagesToSalesman',
        nameInMenu: 'Mensagens para corretor',
        nameInPrisma: DashboardPage.MESSAGES_TO_SALESMAN_PAGE,
        icon: <MailOutlined />,
        roles: ROUTES.messagesToSalesman.roles,
      },
      {
        routeKey: 'errorsOccurred',
        nameInMenu: 'Erros ocorridos',
        icon: <WarningOutlined />,
        roles: ROUTES.errorsOccurred.roles,
        nameInPrisma: DashboardPage.ERRORS_OCCURRED_PAGE,
      },
      {
        routeKey: 'clientsSums',
        nameInMenu: 'Somatórios',
        nameInPrisma: DashboardPage.CLIENTS_SUM_PAGE,
        icon: <BarChartOutlined />,
        roles: ROUTES.clientsSums.roles,
      },
    ]
  },
  {
    key: 'realEstates',
    nameInMenu: 'Imóveis',
    icon: <HomeOutlined />,
    children: [
      {
        routeKey: 'realEstatesStatistics',
        nameInMenu: 'Estatísticas',
        nameInPrisma: DashboardPage.REAL_ESTATE_STATISTICS_PAGE,
        icon: <BarChartOutlined />,
        roles: ROUTES.realEstatesStatistics.roles,
      },
      {
        routeKey: 'portfolioAnalysis',
        nameInMenu: 'Análise de carteira',
        nameInPrisma: DashboardPage.PORTIFOLIO_ANALYSIS_PAGE,
        icon: <BarChartOutlined />,
        roles: ROUTES.portfolioAnalysis.roles,
      },
      {
        routeKey: 'realEstatesRecommendation',
        nameInMenu: 'Recomendação de imóveis',
        icon: <RadarChartOutlined />,
        roles: ROUTES.realEstatesRecommendation.roles,
        nameInPrisma: DashboardPage.REAL_ESTATE_RECOMMENDATION_PAGE,
      },
      {
        routeKey: 'realEstatesSearch',
        nameInPrisma: DashboardPage.REAL_ESTATE_SEARCH_PAGE,
        nameInMenu: 'Pesquisa de imóveis',
        icon: <SearchOutlined />,
        roles: ROUTES.realEstatesSearch.roles,
      },
      {
        routeKey: 'realEstatesDataQuality',
        nameInMenu: 'Qualidade dos dados',
        nameInPrisma: DashboardPage.REAL_ESTATE_DATA_QUALITY_PAGE,
        icon: <ZoomInOutlined />,
        roles: ROUTES.realEstatesDataQuality.roles,
      },
    ]
  },
  {
    key: 'configs',
    nameInMenu: 'Configurações',
    icon: <SettingOutlined />,
    children: [
      {
        routeKey: 'passwordChange',
        nameInMenu: 'Mudar senha',
        icon: <LockOutlined />,
        roles: ROUTES.passwordChange.roles,
      },
      {
        routeKey: 'myProfile',
        nameInMenu: 'Meus dados',
        icon: <ProfileOutlined />,
        roles: ROUTES.myProfile.roles,
      },
      {
        routeKey: 'regionEdit',
        nameInMenu: 'Editar Regiões',
        nameInPrisma: DashboardPage.REGION_EDIT_PAGE,
        icon: <GlobalOutlined />,
        roles: ROUTES.regionEdit.roles,
      },
    ]
  },
  {
    key: 'management',
    nameInMenu: 'Gerenciamento',
    icon: <AppstoreOutlined />,
    children: [
      // {
      //   routeKey: 'agencyCreate',
      //   nameInMenu: 'Incluir Agência',
      //   icon: 'plus-circle',
      // },
      {
        routeKey: 'failChat',
        nameInMenu: 'Erros observados',
        nameInPrisma: DashboardPage.FAIL_CHAT_PAGE,
        icon: <ExclamationCircleOutlined />,
        roles: ROUTES.failChat.roles,
      },
      {
        routeKey: 'users',
        nameInMenu: 'Usuários',
        nameInPrisma: DashboardPage.USERS_PAGE,
        icon: <PlusCircleOutlined />,
        roles: ROUTES.users.roles,
      },
      {
        routeKey: 'agencies',
        nameInMenu: 'Agências',
        nameInPrisma: DashboardPage.AGENCIES_PAGE,
        icon: <ShopOutlined />,
        roles: ROUTES.agencies.roles,
      },
      {
        routeKey: 'roulette',
        nameInMenu: 'Roleta',
        icon: <ContactsOutlined />,
        roles: ROUTES.roulette.roles,
        nameInPrisma: DashboardPage.ROULETTE_PAGE,
      },
      {
        routeKey: 'rouletteEvents',
        nameInMenu: 'Eventos da Roleta',
        icon: <ContactsOutlined />,
        roles: ROUTES.rouletteEvents.roles,
        nameInPrisma: DashboardPage.ROULETTE_PAGE,
      },
      {
        routeKey: 'scheduleMessages',
        nameInMenu: 'Mensagens Agendadas',
        icon: <FieldTimeOutlined />,
        roles: ROUTES.scheduleMessages.roles,
        nameInPrisma: DashboardPage.SCHEDULE_MESSAGES_PAGE,
        shouldHide: () => !!process.env.REACT_APP_WHITELABEL_BUILD,
      },
      {
        routeKey: 'realEstateAgenciesDatabases',
        nameInMenu: 'Bases de dados de imóveis',
        nameInPrisma: DashboardPage.REAL_ESTATE_AGENCIES_DATABASES_PAGE,
        icon: <DatabaseOutlined />,
        roles: ROUTES.realEstateAgenciesDatabases.roles,
      },
      {
        routeKey: 'clientsEmails',
        nameInMenu: 'E-mails',
        nameInPrisma: DashboardPage.CLIENTS_EMAILS_PAGE,
        icon: <MailOutlined />,
        roles: ROUTES.clientsEmails.roles,
      },
    ]
  },
]

export default class PagesController {

  /**
   * Returns the menu tree for the pages the user has access to.
   * @param args 
   */
  public static getAntdMenuItems(args?: { user: User, menuItems?: MenuItem[], routes?: Routes }): MenuProps['items'] {
    const { user = undefined, menuItems = MENU_ITEMS, routes = ROUTES } = args || {}
    const antdMenuItems: MenuProps['items'] = []

    for (const menuItem of menuItems) {
      if (menuItem.roles && user && !castArray(menuItem.roles).includes(user.role))
        continue
      if (menuItem.nameInPrisma && user?.agent?.pages && !user.agent.pages.some(cur => cur === menuItem.nameInPrisma))
        continue
      if (menuItem.roles && !user)
        continue
      if (user && menuItem.shouldHide && menuItem.shouldHide(user))
        continue

      if (menuItem.nameInMenu) {
        if (menuItem.children) {
          const childMenuItems = this.getAntdMenuItems({ ...args, menuItems: menuItem.children })
          if (isEmpty(childMenuItems))
            continue

          antdMenuItems.push({
            key: menuItem.key || menuItem.routeKey,
            icon: menuItem.icon,
            label: menuItem.nameInMenu,
            children: childMenuItems,
          })
        } else {
          const routeKey = menuItem.routeKey
          const route = routes[routeKey]
          if (routeKey && !route) {
            console.log(`[PagesController, getAntdMenuItems] route not found for routeKey = '${routeKey}'`)
          }

          const path = route && route.getUrl ? route.getUrl() :
            route && route.routeProps ?
              castArray(castArray(route.routeProps)[0].path)[0]
              : null

          antdMenuItems.push({
            key: menuItem.key || menuItem.routeKey,
            icon: menuItem.icon,
            label: <NavLink to={path}>
              <span>{menuItem.nameInMenu}</span>
            </NavLink>,
          })
        }
      }
    }
    return antdMenuItems
  }

  /**
   * Returns the routes for the pages the user has access to.
   * @param args 
   */
  public static getReactRouterRoutes(args?: {
    user?: User,
    routes?: Routes,
    /** only return routes wich are accesible without login */
    openRoutesOnly?: boolean,
    /** only return routes that harry users can access */
    whitelabelRoutesOnly?: boolean,
  }): JSX.Element[] {
    const { user = undefined, routes = ROUTES, openRoutesOnly, whitelabelRoutesOnly } = args || {}
    const reactRouterRoutes: JSX.Element[] = []

    for (const routeKey in routes) {
      const route = routes[routeKey as RouteKey]
      if (route.roles && user && !castArray(route.roles).includes(user.role))
        continue
      if (route.nameInPrisma && user && user.agent.pages && !user.agent.pages.some(cur => cur === route.nameInPrisma))
        continue
      if (route.roles && !user)
        continue
      if (openRoutesOnly && !route.isOpenRoute)
        continue
      if (whitelabelRoutesOnly && !route.isWhitelabelRoute)
        continue

      if (route.routeProps) {
        reactRouterRoutes.push(
          ...castArray(route.routeProps).map(routeProps =>
            <Route key={routeKey} {...routeProps} />
          )
        )
      }
    }
    return reactRouterRoutes
  }

}
